body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:url('bg.png');
  background-attachment: fixed;
  background: black;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

* {
  user-select: none;
}

#chat-embed {
  background: black;
}

.pace {
  z-index: 9999999999999999;
position: relative;
height: 100%;
width: 100%;
}

.hidden { opacity: 0; }
.visible { opacity: 1; transition: opacity 3s linear;}

.pace {
  background: black !important;
  z-index: 999999999999999999999999999;
}

.pace-progress-inner {
  border: 1.5px solid white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 678px) {
  body {
    overflow-y: scroll;
  }
}
